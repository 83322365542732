<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ modalData != null ? $t('address.update_btn') : $t('address.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col cols='12' md='6'>
          <text-input
            id='name'
            v-model='form.name'
            :label="$t('addresses.form.name')"
            rules='required'
            :placeholder="$t('form.text.placeholder')"
          />
        </b-col>
        <b-col md='6'>
          <wameed-dropdown
            :searchable='true'
            v-model='form.type'
            :label="$t('addresses.form.type')"
            :placeholder="$t('form.text.placeholder')"
            variant='disable'
            :items='getAddressTypes'
            title='name'
            class='wameed_dropdown'

            :no_options="$t('common.no_options')"

            @input='onTypeChange'
          />
        </b-col>
        <b-col md='6'>
          <TextInput
            v-model='form.phone'
            field-classes='w-100'
            :label="$t('form.phone.label')"
            :placeholder="$t('form.text.placeholder')"
            type='number'
          />
        </b-col>


        <b-col cols='12' md='6'>
          <div @click='showMapModal = true'>
            <text-input
              id='map-address'
              v-model='form.geo_location'
              readonly
              name='location'
              rules='required'
              :label="$t('addresses.form.location')"
              :placeholder="$t('form.address.geoLocation_placeholder')"
              append-class='append-btn append-gray'
              is-append
              :append-text="$t('btn.add')"
            />
          </div>
        </b-col>

        <b-col cols='12' md='12'>
          <wameed-text-area
            v-model='form.address'
            :label="$t('addresses.form.address')"
            inputClasses='text-med-14 text-font-main'
            name='personalInformation'
            :placeholder="$t('form.text.placeholder')"
            rows='5'
          />
        </b-col>

      </b-row>
      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>

    <map-modal
      @setLocation='setLocation'
      :visible='showMapModal'
      @close='showMapModal = false'
    />

  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions, mapGetters } from 'vuex';
import MapModal from '@/components/mapModal.vue';


export default {
  components: {
    MapModal,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId:null,

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      form: {
        geo_location: '',
        name: '',
        phone: '',
        type: '',
        address_type_id: '',
        address: ''
      },
      showMapModal: false,
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getAddressTypes: 'admin/users/getAddressTypes'
    }),


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        this.form.service = this.getServices.filter(type => type.id === this.form.service_id)[0];
        this.form.vendor = this.getVendors.filter(vendor => vendor.id === this.form.vendor_id)[0];
        if (this.showRedirectDropDown) {
          this.form.redirect_obj = this.getRedirects.filter(redirect => redirect.id === this.form.redirect)[0];
        }
      } else {
        this.form = {
          geo_location: '',
          name: '',
          phone: '',
          type: '',
          address_type_id: '',
          address: ''
        };
      }
    }
  },
  created() {
    this.$store.dispatch('admin/users/getAddressTypes');
    if (this.modalData != null) {
      this.form = this.modalData;
    }
  },
  methods: {
    ...mapActions({
      create: 'admin/users/createAddress',
    }),
    setLocation(value) {
      // this.form.address = value.address;
      this.form.geo_location = value.location;
      this.showMapModal = false;
    },


    async onTypeChange(type) {
      this.form.type = type;
      this.form.address_type_id = type.id;

    },


    submitOrder() {

      if (this.modalData == null) {

        this.form.user_id = this.userId;
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    closeModal() {
      this.form = {
        geo_location: '',
        name: '',
        phone: '',
        type: '',
        address_type_id: '',
        address: ''
      };
      this.show = false;
    }
  }
};
</script>
