<template>
  <div>


    <div class='order-detail-table'>
      <b-card
        no-body
        class='wameed-card'
      >
        <b-card-header class='align-items-start tab-title'>
          <h5 class='text-med-18 text-font-main'>
            {{ $t('orders.client_detail') }}
          </h5>
        </b-card-header>

        <b-card-body
          class=' position-relative justify-content-between px-5 p-6'
        >
          <b-row>

            <b-col cols='12' md='4'>

              <div @click='onUserChange' style='cursor: pointer;'>
                <wameed-dropdown
                  style='pointer-events: none'
                  v-model='form.user'
                  :label="$t('orders.new.client')"
                  :placeholder="$t('orders.new.client')"
                  variant='disable'
                  :items='[]'
                  class='wameed_dropdown'
                  title='name'
                  rules='required'
                  :no_options="$t('common.no_options')"
                />
              </div>
            </b-col>
            <b-col cols='12' md='4' v-if='form.user && isDeliveryOrder'>

              <div style='display:flex;align-items:center;'>
                <wameed-dropdown
                  style='width:90%'
                  v-model='form.from_address'
                  :label="$t('orders.new.from_address')"
                  :placeholder="$t('orders.new.from_address')"
                  variant='disable'
                  :items='addresses'
                  class='wameed_dropdown'
                  title='name'
                  rules='required'
                  :no_options="$t('common.no_options')"
                  @input='onSelectFromAddress'
                />
                <div class='client-add-address' @click='showAddAddressModel=true'>
                  <o-plus-icon />
                </div>
              </div>
            </b-col>
            <b-col cols='12' md='4' v-if='form.user'>

              <div style='display:flex;align-items:center;'>
                <wameed-dropdown
                  style='width:90%'
                  v-model='form.to_address'
                  :label="$t('orders.new.to_address')"
                  :placeholder="$t('orders.new.to_address')"
                  variant='disable'
                  :items='addresses'
                  class='wameed_dropdown'
                  title='name'
                  rules='required'
                  :no_options="$t('common.no_options')"
                  @input='onSelectToAddress'
                />
                <div class='client-add-address' @click='showAddAddressModel=true'>
                  <o-plus-icon />
                </div>
              </div>
            </b-col>
            <b-col cols='12' md='4'>
              <Wameed-date-picker
                v-model='form.order_time'
                placeholder='YYYY-MM-DD, HH:mm'
                :config='{"type":"string","mask":"YYYY-MM-DD HH:mm"}'
                mode='datetime'
                :label="$t('orders.new.date')"
                @input='onChangeTime'
              />
            </b-col>
            <b-col cols='12' md='4'>

              <div>
                <wameed-dropdown
                  v-model='form.vehicle'
                  :label="$t('orders.new.vehicle')"
                  :placeholder="$t('orders.new.vehicle')"
                  variant='disable'
                  :items='vehicles'
                  class='wameed_dropdown'
                  title='name'
                  rules='required'
                  :no_options="$t('common.no_options')"
                  @input='onVehicleChange'
                />
              </div>
            </b-col>
            <b-col cols='12' md='4' v-if='isDeliveryOrder'>

              <div>
                <wameed-dropdown
                  v-model='form.item_type'
                  :label="$t('orders.new.order_type')"
                  :placeholder="$t('orders.new.order_type')"
                  variant='disable'
                  :items='orderTypes'
                  class='wameed_dropdown'
                  title='name'
                  rules='required'
                  :no_options="$t('common.no_options')"
                  @input='onItemTypeChange'
                />
              </div>
            </b-col>
            <b-col cols='12' md='4'>

              <text-input
                id='name'
                v-model='form.coupon'
                :label="$t('orders.new.coupon')"
                @input='onChangeCoupon'
                :placeholder="$t('form.text.placeholder')"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>


    <user-filter
      :visible='showUserFilterModel'
      @close='showUserFilterModel = false'
      @onComplete='onSelectUser'
    />
    <user-address
      v-if='form.user'
      :visible='showAddAddressModel'
      :user-id='form.user.id'
      @close='showAddAddressModel=false'
      @onComplete='getAddresses'
    />
  </div>

</template>

<script>

import UserFilter from '@/views/pages/orders/newOrder/userFilter.vue';
import { WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import UserAddress from '@/views/pages/orders/newOrder/userAddress.vue';


export default {
  components: {
    UserAddress,
    UserFilter,
    TextInput
  },
  data() {
    return {

      form: {
        user: null,
        vehicle: null,
        order_time: null,
        coupon: null,
        to_address: null,
        from_address: null
      },
      showUserFilterModel: false,
      showAddAddressModel: false
    };
  },
  computed: {
    addresses() {
      return this.$store.getters['admin/newOrder/getAddresses'];
    },
    vehicles() {
      return this.$store.getters['admin/newOrder/getVehicleTypes'];
    },
    orderTypes() {
      return this.$store.getters['admin/newOrder/getOrderTypes'];
    },

    isDeliveryOrder() {
      return this.$route.meta.type === 'delivery';
    }
  },

  methods: {

    async getAddresses() {
      await this.$store.dispatch('admin/newOrder/getAddresses', this.form.user.id);
    },
    //new methods
    onUserChange() {
      this.showUserFilterModel = true;
    },

    async onSelectUser(data) {
      this.form.user = data;
      this.showUserFilterModel = false;
      this.$emit('onChange', this.form);
      await this.$store.dispatch('admin/newOrder/getAddresses', data.id);
    },

    onVehicleChange(data) {
      this.form.vehicle = data;
      this.$emit('onChange', this.form);
    },

    onChangeTime(data) {
      this.form.order_time = data;
      this.$emit('onChange', this.form);
    },

    onChangeCoupon(data) {
      this.form.coupon = data;
      this.$emit('onChange', this.form);
    },

    onSelectToAddress(data) {
      this.form.to_address = data;
      //remove the from_address from this.form if is not delivery
      if (!this.isDeliveryOrder) {
        //remove it from the object
        delete this.form.from_address;
      }
      this.$emit('onChange', this.form);
    },
    onSelectFromAddress(data) {
      this.form.from_address = data;
      this.$emit('onChange', this.form);
    },

    onItemTypeChange(data) {
      this.form.item_type = data;
      this.$emit('onChange', this.form);
    }


    //end new methods


  }


};
</script>

<style>
.client-add-address {

  padding: 10px;
  background: #f8f9fb;
  border-radius: 10px;
  margin-top: 16px;
  cursor: pointer;

}

.client-add-address:hover {

  background: #c5c6c7;
}
</style>