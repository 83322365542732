<template>
  <div>


    <b-card
      no-body
      class='wameed-card mb-5'
      v-if='vendorProducts.length>0 && vendorId!=0'
    >
      <div class='vendor-products-section'>

        <b-card-header class='align-items-start tab-title'>
          <h5 class='text-med-18 text-font-main'>
            {{ $t('orders.products.title') }}
          </h5>
          <div class='search'>
            <text-input
              v-model='productSearch'
              icon='search-icon'
              is-append
              input-classes='text-reg-14 '
              :placeholder="$t('common.search') + ' ...'"
              field-classes='mb-0 search-form-control'
            />
          </div>
        </b-card-header>

        <div class='order-vendor-categories p-5'>

          <div v-for='cat in vendorProducts' :key='cat.id'>
            <template v-if='cat.products && cat.products.length>0'>
              <div class='category-name'>{{ cat.name }}</div>
              <div class='order-vendor-products'>

                <div
                  v-for="product in cat.products.filter((prod)=>prod.name.indexOf(productSearch) > -1 ||productSearch=='')"
                  :key='product.id'>
                  <product-card :data='product' @onClick='()=>{productModalData = null;onProductClick(product.id)}' />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-card>

    <product-detail-modal
      :visible='showProductModal'
      :order-id='0'
      :cart-product='productModalData'
      @close='showProductModal =false'
      :new-order='true'
      @onSubmit='updateCartItems'
    />

  </div>

</template>

<script>


import { WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';

import ProductDetailModal from '@/views/pages/orders/components/productDetailModal.vue';
import ProductCard from '@/views/pages/orders/components/productCard.vue';

export default {
  components: {
    ProductCard,
    ProductDetailModal,
    TextInput
  },
  data() {
    return {
      showProductModal: false,
      productModalData: null,
      platformOrderData: {},
      productSearch: ''
    };
  },
  props: {
    vendorId: {
      type: Number,
      required: true
    }
  },
  computed: {
    vendorProducts() {
      return this.$store.getters['admin/newOrder/getProducts'];
    }

  },

  methods: {

    updateCartItems(data) {
      console.log('--------------------------------');
      console.log(data);
      console.log('--------------------------------');
      this.productModalData = null;

      this.$store.dispatch('admin/newOrder/updateCart', data);

      this.showProductModal = false;
    },


    onProductClick(id) {
      this.$store.dispatch('admin/newOrder/productDetail', this.vendorId + '/' + id)
        .then(() => {
            this.showProductModal = true;
          }
        );
    }

  }


};
</script>
