<template>

  <b-modal
    v-model='show'
    content-class='package-modal rounded-8'
    dialog-class='wameed-modal-sm'
    no-fade
  >
    <template slot='modal-header-close'>
      <close-icon />
    </template>
    <template slot='modal-title'>
      <div class='d-block text-center'>
        <h3 class='text-reg-18'>
          {{ $t('table.total') }}
        </h3>
      </div>
    </template>


    <b-row>

      <b-col md='12' >
        <div class='p-5 d-flex justify-content-center'>

          <div class='bill-detail px-4 '>
            <bill-price-tile
              :data='getPriceDetail.order'
              :title="$t('orders.bill.order')"
            />
            <template v-if='getPriceDetail'>


              <bill-price-tile
                :data='getPriceDetail.service'
                :title="$t('orders.bill.service')"
                type='service'
              />

              <bill-price-tile
                :data='getPriceDetail.delivery'
                :title="$t('orders.bill.delivery')"
              />
              <bill-price-tile
                :data='getPriceDetail.coupon'
                :title="$t('orders.bill.discount')"
                type='coupon'
              />

            </template>
            <div class='h-divider'></div>
            <bill-price-tile
              :data='{price:getPriceDetail.total_price}'
              :title="$t('table.total')"
              type='total'
            />
          </div>
        </div>
      </b-col>


    </b-row>


    <template slot='modal-footer'>
      <wameed-btn
        classes='  text-med-14 text-white mx-0 rounded-8'
        :title="$t('common.complete_order')"
        type='submit'
        variant='main'
        @onClick='submitOrder()'
      />
      <wameed-btn
        classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
        :title="$t('btn.cancel')"
        type='button'
        variant='gray'
        @onClick='closeModal()'
      />
    </template>
  </b-modal>

</template>

<script>
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapGetters } from 'vuex';
import BillPriceTile from '@/views/pages/orders/components/billPriceTile.vue';


export default {
  components: {
    BillPriceTile,

    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }

  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getPriceDetail: 'admin/newOrder/getPriceDetail'
    }),

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },

  methods: {

    submitOrder() {

      this.$emit('onComplete');
      this.closeModal();

    },


    closeModal() {

      this.show = false;
    }
  }
};
</script>
